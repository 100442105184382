import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';


//Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//Rutas
import { APP_ROUTING } from './app.route';

//Servicips

//Componentes
import { AppComponent } from './app.component';
import { BarraMenuComponent } from './components/barra-menu/barra-menu.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { FooterComponent } from './components/footer/footer.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CvComponent } from './components/cv/cv.component';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TranslationComponent } from './components/translation/translation.component';



@NgModule({ declarations: [
        AppComponent,
        BarraMenuComponent,
        InicioComponent,
        ContactoComponent,
        FooterComponent,
        NotFoundComponent,
        CvComponent,
        TranslationComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => {
                    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
                },
                deps: [HttpClient]
            }
        }),
        APP_ROUTING], providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
