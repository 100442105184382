<div class="jumbotron jumbotron-fluid">
    <div class="container">
        <h1 class="display-4">{{ 'website.contact.page.title' | translate }}</h1>
        <p class="lead">{{ 'website.contact.page.description' | translate }}</p>
    </div>
</div>
<div class="container py-4">
    <div>
        <form class="text-center row" (ngSubmit)="sendMessage( form )" required #form="ngForm">
            <div class="form-group col-12 col-md-4">
                <label for="formGroupExampleInput">{{ 'website.contact.page.form.name' | translate }}</label>
                <input type="text" class="form-control" ngModel name="name" placeholder="Elton Tito" required>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="formGroupExampleInput">{{ 'website.contact.page.form.phone_number' | translate }}</label>
                <input type="number" class="form-control" ngModel name="phone" placeholder="(111) 123 4567" required>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="formGroupExampleInput">{{ 'website.contact.page.form.email' | translate }}</label>
                <input type="mail" class="form-control" ngModel name="email" placeholder="makinadefuego69@email.com" required>
            </div>
            <div class="form-group col-12 col-md-12">
                <label for="formGroupExampleInput">{{ 'website.contact.page.form.message' | translate }}</label>
                <textarea type="text" class="form-control" rows="10" cols="50" ngModel name="message" required placeholder="Abrí un bote de crema Nivea y lamí el papel de aluminio. ¡No es tan sabroso como el yogur!"></textarea>
            </div>
            <div class="container-fluid h-100">
                <div class="row w-100 align-items-center">
                    <div class="col text-center">
                        <input class="btn btn-primary" type="submit" value="{{ 'website.contact.page.form.button' | translate }}" ng-disable="emptyForm">
                    </div>
                </div>
            </div>
        </form>
        <div class="my-5">
            <div *ngIf="emptyForm">
                <p class="alert alert-warning">{{ 'website.contact.page.errors.empty' | translate }}</p>
            </div>
            <div *ngIf="sentMessage">
                <p class="alert alert-success">{{ 'website.contact.page.errors.success' | translate }}</p>
            </div>
            <div *ngIf="errorSentMessage">
                <p class="alert alert-danger">{{ 'website.contact.page.errors.error' | translate }}</p>
            </div>
        </div>
    </div>
</div>