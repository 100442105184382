<div class="jumbotron">
  <h1 class="display-4 text-center text-secondary">Error 404</h1>
  <div class="text-center">
    <img class="img-fluid" src="../../assets/images/error.png" width="150">
  </div>
  <p class="lead offset-md-4 col-12 col-md-4 text-justify text-secondary">
    !UPS, algo salió mal...! Pero recuerda que por principio cuántico de superposición y el estado Hartle-Hawking, 
    posiblemente en otro momento del tiempo sí haz encontrado lo que buscabas... pero lastimosamente, escogiste
    un mal momento para ser observador.
  </p>
</div>