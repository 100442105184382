<div class="jumbotron jumbotron-fluid">
    <div class="container">
        <h1 class="display-4">{{ 'website.cv.page.title' | translate }}</h1>
        <p class="lead">{{ 'website.cv.page.description' | translate }}</p>
    </div>
</div>
<div class="container my-5">
    <div class="row">
        <div class="col-12">
            <p class="display-3 text-center"> {{ 'website.cv.content.name' | translate }} </p>
            <p class="display-4 text-center">{{ 'website.cv.content.profession' | translate }}</p>
            <hr class="separacion">
        </div>
        <!-- Contacto -->
        <div class="col-md-6 col-sm-12">
            <p class="titulo-3"> <strong>{{ 'website.cv.content.contact.title' | translate }}</strong> </p>
            <hr class="separacion">
            <ul class="row">
                <li class="col-6"><i class="fas fa-envelope"></i> {{ 'website.cv.content.contact.email' | translate }} </li>
                <li class="col-6"><i class="fas fa-location-arrow"></i> {{ 'website.cv.content.contact.location' | translate }}</li>
                <li class="col-6"><i class="fab fa-linkedin"></i> <a href="https://www.linkedin.com/in/sdfonseca1/"> {{ 'website.cv.content.contact.linkedin' | translate }}</a></li>
                <li class="col-6"><i class="fab fa-gitlab"></i> <a href="https://gitlab.com/sdfonseca/"> {{ 'website.cv.content.contact.gitlab' | translate }}</a> </li>
            </ul>
        </div>
        <!-- Intereses -->
        <div class="col-md-6 col-sm-12">
            <p class="titulo-3"> <strong>{{ 'website.cv.content.interests.title' | translate }}</strong> </p>
            <hr class="separacion">
            <div>
                <p class="badge badge-pill badge-secondary"> {{ 'website.cv.content.interests.interest1' | translate }}</p>
                <p class="badge badge-pill badge-secondary"> {{ 'website.cv.content.interests.interest2' | translate }}</p>
                <p class="badge badge-pill badge-secondary"> {{ 'website.cv.content.interests.interest3' | translate }}</p>
                <p class="badge badge-pill badge-secondary"> {{ 'website.cv.content.interests.interest4' | translate }}</p>
                <p class="badge badge-pill badge-secondary"> {{ 'website.cv.content.interests.interest5' | translate }}</p>
                <p class="badge badge-pill badge-secondary"> {{ 'website.cv.content.interests.interest6' | translate }}</p>
                <p class="badge badge-pill badge-secondary"> {{ 'website.cv.content.interests.interest7' | translate }}</p>
            </div>
        </div>
        <!-- Experiencia -->
        <div class="col-12">
            <p class="titulo-3"> <strong>{{ 'website.cv.content.experience.title' | translate }}</strong> </p>
            <hr class="separacion">
            <p> {{ 'website.cv.content.experience.ex1.activity' | translate }} | {{ 'website.cv.content.experience.ex1.location' | translate }} | <small>{{ 'website.cv.content.experience.ex1.duration' | translate }}</small></p>
            <p> {{ 'website.cv.content.experience.ex1.context' | translate }} </p>
            <p> {{ 'website.cv.content.experience.ex2.activity' | translate }} | {{ 'website.cv.content.experience.ex2.location' | translate }} | <small>{{ 'website.cv.content.experience.ex2.duration' | translate }}</small></p>
            <p> {{ 'website.cv.content.experience.ex2.context' | translate }} </p>
            <p> {{ 'website.cv.content.experience.ex3.activity' | translate }} | {{ 'website.cv.content.experience.ex3.location' | translate }} | <small>{{ 'website.cv.content.experience.ex3.duration' | translate }}</small></p>
            <p> {{ 'website.cv.content.experience.ex3.context' | translate }} </p>
        </div>
        <!-- Idiomas -->
        <div class="col-md-6 col-sm-12">
            <p class="titulo-3"> <strong>{{ 'website.cv.content.languages.title' | translate }}</strong> </p>
            <hr class="separacion">
            <ul>
                <li> <img src="assets/images/es.png"> {{ 'website.cv.content.languages.language1' | translate }} </li>
                <li> <img src="assets/images/us.png"> {{ 'website.cv.content.languages.language2' | translate }} </li>
                <li> <img src="assets/images/pt.png"> {{ 'website.cv.content.languages.language3' | translate }} </li>
            </ul>
        </div>
        <!-- Proyectos -->
        <div class="col-md-6 col-sm-12">
            <p class="titulo-3"> <strong>{{ 'website.cv.content.projects.title' | translate }}</strong> </p>
            <hr class="separacion">
            <ul>
                <li> <i class="fas fa-desktop"></i> {{ 'website.cv.content.projects.project1' | translate }}</li>
                <li> <i class="fas fa-desktop"></i> {{ 'website.cv.content.projects.project2' | translate }}</li>
                <li> <i class="fas fa-desktop"></i> {{ 'website.cv.content.projects.project3' | translate }}</li>
                <li> <i class="fas fa-desktop"></i> {{ 'website.cv.content.projects.project4' | translate }}</li>
                <li> <i class="fas fa-desktop"></i> {{ 'website.cv.content.projects.project5' | translate }}</li>
            </ul>
        </div>
        <!-- Educación -->
        <div class="col-md-6 col-sm-12">
            <p class="titulo-3"> <strong> {{ 'website.cv.content.education.title' | translate }} </strong> </p>
            <hr class="separacion">
            <ul>
                <li> <strong>{{ 'website.cv.content.education.mainEducation.place' | translate }} </strong>
                    <p>{{ 'website.cv.content.education.mainEducation.bachelor' | translate }} | {{ 'website.cv.content.education.mainEducation.duration' | translate }} </p>
                </li>
                <li> <strong>{{ 'website.cv.content.education.certifications.cert1.institution' | translate }}</strong>
                    <p>{{ 'website.cv.content.education.certifications.cert1.name' | translate }} | ID: <a href="https://learndigital.withgoogle.com/activate/validate-certificate-code">{{ 'website.cv.content.education.certifications.cert1.code' | translate }}</a></p>
                </li>
                <li> <strong>{{ 'website.cv.content.education.certifications.cert2.institution' | translate }}</strong>
                    <p>{{ 'website.cv.content.education.certifications.cert2.name' | translate }}| ID: <a href="https://coursera.org/verify/6RR2A6GHDREH">{{ 'website.cv.content.education.certifications.cert2.code' | translate }}</a></p>
                </li>
                <li> <strong>{{ 'website.cv.content.education.certifications.cert3.institution' | translate }}</strong>
                    <p>{{ 'website.cv.content.education.certifications.cert3.name' | translate }} | ID: {{ 'website.cv.content.education.certifications.cert3.code' | translate }}</p>
                </li>
                <li> <strong>{{ 'website.cv.content.education.certifications.cert4.institution' | translate }}</strong>
                    <p>{{ 'website.cv.content.education.certifications.cert4.name' | translate }} | ID: {{ 'website.cv.content.education.certifications.cert4.code' | translate }}</p>
                </li>
            </ul>
        </div>
        <!-- Habilidades -->
        <div class="col-md-6 col-sm-12">
            <p class="titulo-3"> <strong>{{ 'website.cv.content.skills.title' | translate }}</strong> </p>
            <hr class="separacion">
            <div class="row">
                <div class="col-3">
                    <p>Javascript</p>
                    <p>Python</p>
                    <p>AWS</p>
                    <p>Linux</p>
                    <p>SQL</p>
                    <p>Azure</p>
                    <p>GCP</p>
                    <p>Windows</p>
                    <p>Rackspace Cloud</p>
                </div>
                <div class="col-9">
                    <p class="badges-background">
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>
                    </p>
                    <p class="badges-background">
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>
                    </p>
                    <p class="badges-background">
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>
                    </p>
                    <p class="badges-background">
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>
                    </p>
                    <p class="badges-background">
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>
                    </p>
                    <p class="badges-background">
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>
                    </p>
                    <p class="badges-background">
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>
                    </p>
                    <p class="badges-background">
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>
                    </p>
                    <p class="badges-background">
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-dark">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>&nbsp;
                        <span class="badge badge-pill badge-light">&nbsp;</span>
                    </p>
                </div>
            </div>
        </div>


    </div>