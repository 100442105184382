import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-barra-menu',
  templateUrl: './barra-menu.component.html',
})
export class BarraMenuComponent {

  public activeLang = 'es';

  constructor( private translate:TranslateService ) { 
    this.translate.setDefaultLang( this.activeLang )
  }

  public changeLanguage( lang ){
    this.activeLang = lang;
    this.translate.use( lang )
  }
}
