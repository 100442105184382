<footer class="section footer-classic context-light bg-image text-light" style="background: #2d3246;">

    <div class="container">
        <div class="row row-30">
            <div class="col-md-4 col-xl-5 my-5">
                <div class="pr-xl-4">
                    <a class="brand" href="index.html">
                        <img class="brand-logo-light" src="assets/images/branch.png" alt="" width="140" height="60" srcset="">
                    </a>
                    <p>{{ 'website.footer.phrase' | translate }}</p>
                    <p class="rights"> {{ 'website.footer.copyright' | translate }} </p>
                </div>
                <dl class="contact-list">
                    <dd><i>{{ 'website.footer.challenge' | translate }}</i></dd>
                </dl>
            </div>
            <div class="col-md-4 my-5">
                <h5>{{ 'website.footer.contact.title' | translate }}</h5>
                <dl class="contact-list">
                    <dt>{{ 'website.footer.contact.address' | translate }}</dt>
                    <dd>{{ 'website.footer.contact.address1' | translate }}<br>{{ 'website.footer.contact.address2' | translate }} {{ 'website.footer.contact.address3' | translate }} {{ 'website.footer.contact.address4' | translate }} {{ 'website.footer.contact.address5'
                        | translate }}</dd>
                </dl>
                <dl class="contact-list">
                    <dt>Email</dt>
                    <dd>{{ 'website.footer.contact.email' | translate }}</dd>
                </dl>
            </div>
            <div class="col-md-4 col-xl-3 my-5">
                <h5>{{ 'website.footer.directory.title' | translate }}</h5>
                <ul class="nav-list">
                    <li>
                        <a [routerLink]="['home']"><i class="fas fa-angle-double-right"></i>{{ 'website.footer.directory.home' | translate }}</a>
                    </li>
                    <li>
                        <a [routerLink]="['cv']"><i class="fas fa-angle-double-right"></i>{{ 'website.footer.directory.cv' | translate }}</a>
                    </li>
                    <li>
                        <a [routerLink]="['contact']"><i class="fas fa-angle-double-right"></i>{{ 'website.footer.directory.contact' | translate }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row no-gutters social-container">
        <div class="col"><a class="social-inner" href="https://www.linkedin.com/in/sdfonseca1/"><i class="fab fa-linkedin"></i><span>LinkedIn</span></a></div>
    </div>
</footer>