import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.css']
})
export class CvComponent implements OnInit {

  public activeLang = 'es';

  constructor( private translate:TranslateService ) { 
    this.translate.setDefaultLang( this.activeLang )
  }

  ngOnInit(): void {
  }

}
