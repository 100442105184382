<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" [routerLink]="['/home']">
        <img src="assets/images/branch.png" alt="logotipo" width="120">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item ">
                <a class="nav-link" [routerLink]="['/home']">{{ 'website.navbar.home' | translate }}<span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/cv']">{{ 'website.navbar.cv' | translate }}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/contact']">{{ 'website.navbar.contact' | translate }}</a>
            </li>
        </ul>
        <ul>
            <a class="float-sm-right"><img src="assets/images/es.png" [ngClass]="{'active': activeLang == 'es'}" (click)="changeLanguage('es')"></a>
            <a class="float-sm-right"><img src="assets/images/us.png" [ngClass]="{'active': activeLang == 'en'}" (click)="changeLanguage('en')"></a>
            <a class="float-sm-right"><img src="assets/images/pt.png" [ngClass]="{'active': activeLang == 'pt'}" (click)="changeLanguage('pt')"></a>
       
        </ul>

    </div>
</nav>