import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: [ './contacto.component.css']
})
export class ContactoComponent implements OnInit {

  public activeLang = 'es';

  mensajes = [
    "Acabas de interrumpir mi 5° sueño del día.",
    "Su mensaje ha sido puesto en el buzón \"No me interesa\" con éxito.",
    "¡Neta! ¿A estas horas me escribes?",
    "Tu mensaje será contestado en: " + Math.random()*135486 + " días.",
  ]
  sentMessage: boolean;
  emptyForm: boolean;
  errorSentMessage: boolean;

  constructor( private http: HttpClient,
               private translate:TranslateService) { 
                this.translate.setDefaultLang( this.activeLang )

               }

  ngOnInit(): void {
  }

  emptyData( data: NgForm ): boolean {
    if ( data.value.name === "" || data.value.phone === ""
      || data.value.email === "" || data.value.message === "" ){
        return true;
      }else{
        return false;
      }
  }

  sendMessage( data: NgForm ){
    if ( this.emptyData( data ) ){
      this.emptyForm = true;
    }else{
      this.emptyForm = false;
      let contact = {
        name: data.value.name,
        phone: data.value.phone,
        email: data.value.email,
        message: data.value.message
      }
      this.http.post('https://1krab6jx02.execute-api.us-east-1.amazonaws.com/Production/email', contact)
      .subscribe( (res: any) => {
        if( res.ok ){
          this.sentMessage = true;
        }else{
          this.errorSentMessage = false;
        }
      });
    }
  }


}
