<section class="filosofia py-4 bg-primary text-center text-white">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <img src="assets/images/sdfonseca.png" width="360" height="auto" alt="Avatar SDFonseca" class="img-fluid rounded-circle mb-4">
                <p class="h2">"{{ 'website.home.phrase' | translate }}" </p>
                <p class="h4 font-italic">{{ 'website.home.signature' | translate }}</p>
            </div>
        </div>
    </div>
</section>
<section class="quien-soy py-4 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 text-center">
                <img src="assets/images/quien-soy.jpeg" alt="Soy el  desarrolador" class="img-fluid mb-4 mb-md-0">
            </div>
            <div class="col-12 col-md-6 text-center text-md-left align-self-md-center ">
                <h1 class="display-4 font-weight-bold text-primary">{{ 'website.home.introduction.title' | translate }}</h1>
                <p>{{ 'website.home.introduction.content1' | translate }}</p>
                <p>{{ 'website.home.introduction.content2' | translate }}</p>
                <p>{{ 'website.home.introduction.content3' | translate }}</p>
            </div>
        </div>
    </div>
</section>