import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './components/inicio/inicio.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CvComponent } from './components/cv/cv.component';
import { TranslateModule } from '@ngx-translate/core';

const APP_ROUTES: Routes = [
  { path: 'home', component: InicioComponent },
  { path: '', component: InicioComponent },
  { path: 'contact', component: ContactoComponent },
  { path: 'cv', component: CvComponent },
  { path: 'error', component: NotFoundComponent },
  { path: '**', pathMatch: 'full', redirectTo: 'error' },
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true });
