import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: [
  ]
})
export class FooterComponent implements OnInit {

  public activeLang = 'es';

  constructor( private translate:TranslateService) { 
    this.translate.setDefaultLang( this.activeLang )
  }

  ngOnInit(): void {
  }

}
