import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html'
})
export class InicioComponent implements OnInit {

  public activeLang = 'es';

  constructor( private translate:TranslateService ) {
    this.translate.setDefaultLang( this.activeLang )
  }

  ngOnInit(): void {
  }

}
